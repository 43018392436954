<template>
  <div class="product-page--create">
    <v-row class="d-flex justify-center " v-if="loadData">
      <v-col sm="12"><Loading class=""/></v-col>
    </v-row>
    <div v-if="!loadData">
      <v-row no-gutters>
        <v-col cols="12">
          <redirect class="mb-5" title="Upsell offers" route="website.apps.bundle.list" />
          <div class="page-header">
            <h2 class="page-title">{{ mode === 'create' ? 'Create bundle' : 'Update bundle' }}</h2>
          </div>
        </v-col>
      </v-row>
      <v-col cols="12">
        <Notification type="error" :messages="errors" v-if="errors.length > 0" />
      </v-col>
      <div>
        <v-row>
          <v-col cols="12" sm="4">
            <h4>Offer's name & message</h4>
          </v-col>
          <!-- <pre>{{ formData }}</pre> -->
          <v-col cols="12" sm="8" class="elevation-form">
            <InputDefault label="Offer's name" :model="['formData', 'offerName']" />
            <small class="input-desc mt-2">
              Give your offer a name to manage easily or leave blank and we will automatically generate a name for you
            </small>
            <InputDefault
              :itemsSelect="itemsMessage"
              typeComponent="select"
              label="Offer message"
              :model="['formData', 'offerMessage']"
            />
          </v-col>
        </v-row>
        <v-col cols="12" class="my-2">
          <v-divider class=""></v-divider>
        </v-col>
        <v-row>
          <v-col cols="12" sm="4">
            <h4>Choose Bundle products</h4>
            <small>Motivate customers to add this bundle to cart when they visit any of the bundled products </small>
          </v-col>
          <v-col cols="12" sm="8" class="elevation-form">
            <v-switch
              class="mt-1"
              style="max-width: 650px; width: 650px"
              v-model="formData.typeBundle"
              @change="changeType($event, 'specific')"
              value="specific"
              color="primary"
              label="Specific Products"
            ></v-switch>
            <div style="max-width: 650px; width: 650px" v-if="formData.typeBundle === 'specific'">
              <div v-if="formData.targetIds.length > 0">
                <span>{{ formData.targetIds.length }} product selected</span>
                <v-divider></v-divider>
                <div class="my-3">
                  <div v-for="(i, k) in formData.targetIds" :key="i._id" :class="k <= 2 || !seeMore ? 'mb-2 pa-1' : ''">
                    <div v-if="k <= 2 || !seeMore" class="d-flex align-center">
                      <v-avatar tile size="40" style="outline: #dddd solid 1px">
                        <img
                          :src="
                            i.images && i.images.length > 0
                              ? imageHelpers.url(i.images[0].src)
                              : '@/assets/images/no-image.png'
                          "
                          alt=""
                        />
                      </v-avatar>
                      <span class="ml-3 link-add">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <h4 v-bind="attrs" v-on="on">{{ utilities.stringLimit(i.title, 50) }}</h4>
                          </template>
                          <span>{{ i.title }}</span>
                        </v-tooltip>
                      </span>
                      <v-spacer />
                      <span v-if="k === 0" dense> Target Product </span>
                      <v-btn v-else small @click="changeTarget(i, k)"> Set as target </v-btn>
                      <i class="ml-3 fas fa-times pointer" @click="removeBundle(i, k)"></i>
                    </div>
                    <div class="link-add" v-if="k == 3 && seeMore">
                      <a href="#" @click="seeMore = false"> See more </a>
                    </div>
                    <div class="link-add" v-if="k === formData.targetIds.length - 1 && !seeMore">
                      <a href="#" @click="seeMore = true"> See less </a>
                    </div>
                    <!-- <v-divider class="mt-2"></v-divider> -->
                  </div>
                </div>
              </div>
              <div v-else class="text-error">No product selected</div>
              <div v-if="formData.targetIds.length > 0" class="my-2 fz-14 font-weight-bold ">
                Total price:{{ sumPrice }}
              </div>
              <v-btn
                small
                elevation=""
                color="primary"
                @click="configDialogSelection({ type: 'products', model: 'targetIds' })"
                >Select products</v-btn
              >

              <v-divider class="mt-3"></v-divider>
              <div>
                <small class="input-desc">
                  Pro tip: According to our statistics, bundle of 2 or 3 products see over 20% conversion
                </small>
              </div>
              <div>
                <v-checkbox
                  v-model="formData.offerProductUpdated"
                  label="Only show this bundle when customers visit target"
                >
                </v-checkbox>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4"></v-col>
          <v-col cols="12" sm="8" class="elevation-form">
            <v-row no-gutters>
              <v-col sm="8">
                <v-switch
                  style="max-width: 650px; width: 650px"
                  class="mt-1"
                  @change="changeType($event, 'smart')"
                  v-model="formData.typeBundle"
                  value="smart"
                  color="primary"
                  label="Smart Bundles"
                ></v-switch>
                <div style="max-width: 650px; width: 650px" v-if="formData.typeBundle === 'smart'">
                  <v-radio-group v-model="formData.optionBundle.recommendType">
                    <v-radio value="sameTitle" color="primary" label="Same Title"></v-radio>
                    <div class="d-flex">
                      <v-radio
                        value="relevant"
                        color="primary"
                        label="Most relevant products using automated rules"
                      ></v-radio>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="grey lighten-1">
                              mdi-information
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Recommend with this priority:<br />
                          - Bought also Bought<br />
                          - Best-seller products in the same collection<br />
                          - Title Similarity<br />
                          - Same collection<br />
                          - Relative tags products<br />
                          - Random<br />
                        </span>
                      </v-tooltip>
                    </div>
                  </v-radio-group>
                </div>
              </v-col>
              <v-col sm="4"
                ><div v-if="formData.typeBundle === 'smart'" cols="4" sm="4" class="d-flex flex-column justify-end">
                  <div>Limited products</div>
                  <v-text-field type="number" v-model="formData.optionBundle.limit"></v-text-field></div
              ></v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-col cols="12" class="my-2">
          <v-divider class=""></v-divider>
        </v-col>
        <v-col cols="12" class="my-2">
          <v-divider class=""></v-divider>
        </v-col>
        <v-row>
          <v-col cols="12" sm="4">
            <h4 class="d-flex align-center">Offer’s discount <v-switch v-model="formData.enableDiscount" /></h4>
            <small class="input-desc mt-2">
              Offer discount and scarcity to motivate customers add Up-sell products to cart
            </small>
          </v-col>
          <v-col cols="12" sm="8" class="elevation-form">
            <InputDefault
              :sufix="currency.currencyCode()"
              label="Discount percentage"
              :model="['formData', 'percentageDiscount']"
            />
          </v-col>

          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn>
            <v-btn :loading="isLoading" :disabled="isDisable" color="primary" @click="onSubmit">
              {{ mode === 'create' ? 'Create' : 'Save' }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-col cols="12" class="my-3">
        <v-divider class=""></v-divider>
      </v-col>
      <v-dialog :scrollable="false" v-model="dialog" width="900" content-class="l-dialog">
        <SelectionItems
          :isIconAddAll="false"
          :isIconRemoveAll="false"
          :model="configSelectionItem.model"
          :type="configSelectionItem.type"
          @returnData="getDataSelected"
          :dialog.sync="dialog"
          :isSuperShow="true"
          :dataSelected="formData.targetIds"
        />
      </v-dialog>
      <BeforeLeavePage />
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import SelectionItems from '../component/SelectionItems';
import { offerApi } from '@/apis/offers';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import utilities from '@/helpers/utilities';
import { convertIdsToArrays } from '@/helpers/convertData';
import currency from '@/helpers/currency';
export default {
  components: {
    SelectionItems,
    Loading,
  },
  computed: {
    sumPrice() {
      return this.formData.targetIds
        .map(item => {
          if (item.variants && item.variants.length > 0) return Number(item.variants[0].price || 0);
          if (item.variantDefault) return Number(item.variantDefault.price || 0);
          return 0;
        })
        .reduce((prev, next) => prev + next)
        .toFixed(2);
    },
  },
  data() {
    return {
      currency,
      loadData: false, // là load data khi qua màn Update
      isLoading: false, // là load data khi ấn Save hoặc Create
      isDisable: false, // là khi off cả 2 switch
      isSmartSelect: '',
      limitProduct: 3,
      isSpecific: true,
      isSmart: false,
      seeMore: false,
      mode: 'create',
      imageHelpers,
      imageFake,
      errors: [],
      configSelectionItem: {
        type: 'products',
        model: 'targetIds',
      },
      utilities,
      dialog: false,
      typeTarget: 'all',
      dataDefault: {},
      itemsMessage: [
        {
          name: 'Frequently bought together',
          id: 'Frequently bought together',
        },
        {
          name: 'Hang on! We have this offer just for you!',
          id: 'Hang on! We have this offer just for you!',
        },
        {
          name: 'Special deal of the day',
          id: 'Special deal of the day',
        },
      ],
      formData: {
        typeBundle: 'specific',
        optionBundle: {
          recommendType: 'sameTitle',
          limit: 3,
        },
        activated: true,
        offerMessage: 'Frequently bought together',
        offerType: 'bundle',
        priority: 0,
        offerName: '',
        offerTitle: null,
        recommendIds: [], // product collection ids ,
        recommendType: 'product', //product, collection, sameCollection, relevantCollection
        targetIds: [],
        targetType: 'product', //all, product, collection
        store: '',
        enableDiscount: true,
        percentageDiscount: 0,
        offerProductUpdated: false,
      },
    };
  },
  watch: {
    'formData.typeBundle': function() {
      if (this.formData.typeBundle === null) {
        this.isDisable = true;
      } else {
        this.isDisable = false;
      }
    },
    'formData.targetType': function(n, o) {
      if (o) {
        this.formData.targetIds = [];
      }
      if (
        (o && this.$route.params.id && this.formData.targetType === 'collection') ||
        this.formData.targetType === 'product'
      ) {
        this.formData.targetIds = this.dataDefault[`${this.formData.targetType}TargetIds`];
      }
    },
    'formData.recommendType': function(n, o) {
      if (o) {
        this.formData.recommendIds = [];
      }
      if (
        (o && this.$route.params.id && this.formData.recommendType === 'collection') ||
        this.formData.recommendType === 'product'
      ) {
        this.formData.recommendIds = this.dataDefault[`${this.formData.recommendType}RecommendIds`];
      }
    },
  },
  async created() {
    if (this.$route.params.id) {
      this.loadData = true;
      this.mode = 'edit';
      try {
        let res = (await offerApi.getById(this.$route.params.id)).data.offer;
        let [collectionRecommendIds, collectionTargetIds, productRecommendIds, productTargetIds] = await Promise.all([
          convertIdsToArrays('collection', res.collectionRecommendIds),
          convertIdsToArrays('collection', res.collectionTargetIds),
          convertIdsToArrays('product', res.productRecommendIds),
          convertIdsToArrays('product', res.productTargetIds),
        ]);
        this.dataDefault = {
          collectionRecommendIds,
          collectionTargetIds,
          productRecommendIds,
          productTargetIds,
        };

        this.formData = {
          typeBundle: res.typeBundle ? res.typeBundle : 'specific',
          optionBundle: res.optionBundle ? res.optionBundle : { recommendType: 'sameTitle', limit: '3' },
          activated: res.activated,
          offerMessage: res.offerMessage,
          offerType: res.offerType,
          priority: res.priority,
          offerName: res.offerName,
          offerTitle: res.offerTitle,
          recommendIds: [], // product collection ids ,
          recommendType: res.recommendType, //product, collection, sameCollection, relevantCollection
          targetIds: [],
          targetType: res.targetType, //all, product, collection
          enableDiscount: res.enableDiscount,
          percentageDiscount: res.percentageDiscount,
          offerProductUpdated: res.offerProductUpdated,
          // discountData: res.discountData,
        };
        this.defaultFormData = Object.assign({}, res);
        // if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
        //   console.log(this.formData.recommendType, res[`${this.formData.recommendType}RecommendIds`]);
        //   this.formData.recommendIds = res[`${this.formData.recommendType}RecommendIds`];
        //   this.dataDefault[`${this.formData.recommendType}RecommendIds`] = this.dataDefault[
        //     `${this.formData.recommendType}RecommendIds`
        //   ];
        // }
        if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
          this.formData.targetIds = this.formData.targetIds.concat(
            this.dataDefault[`${this.formData.targetType}TargetIds`],
          );
          this.dataDefault[`${this.formData.targetType}TargetIds`] = this.dataDefault[
            `${this.formData.targetType}TargetIds`
          ];
        }
        this.$forceUpdate();
        this.loadData = false;
      } catch (error) {
        this.loadData = false;
        //empty
      }
      this.componentMount = true;
    }
  },
  methods: {
    changeType(e, value) {
      if (e) {
        this.formData.typeBundle = value;
        // để khi chuyển trạng thái thì reset lại data đã nhập
        if (this.formData.typeBundle == 'specific') {
          this.formData.optionBundle.recommendType = '';
          this.formData.optionBundle.limit = '';
        }
        if (this.formData.typeBundle == 'smart') {
          let x = [];
          this.formData.targetIds = x.map(i => i);
          this.formData.optionBundle = {
            recommendType: 'sameTitle',
            limit: 3,
          };
        }
        // nếu k chọn gì thì cho về rỗng hết
        // if (this.formData.typeBundle == null) {
        //   let x = [];
        //   this.formData.targetIds = x.map(i => i);
        //   this.formData.optionBundle.recommendType = '';
        //   this.formData.optionBundle.limit = '';
        // }
      }
      this.$forceUpdate();
    },
    changeTarget(i, k) {
      var b = this.formData.targetIds[k];
      this.formData.targetIds[k] = this.formData.targetIds[0];
      this.formData.targetIds[0] = b;
      this.$forceUpdate();
    },
    removeBundle(i, k) {
      this.formData.targetIds.splice(k, 1);
    },
    onCancel() {},
    async onSubmit() {
      if (
        (this.formData.targetIds.length > 0 && this.formData.typeBundle == 'specific') ||
        this.formData.typeBundle == 'smart'
      ) {
        this.isLoading = true;
        this.isDisable = true;
        let convertData = Object.assign({}, this.formData);
        convertData.targetIds = convertData.targetIds.map(item => item._id);
        convertData.recommendIds = convertData.recommendIds.map(item => item._id);
        convertData.store = this.$store.state.auth.accountInfo.storeId;
        if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
          convertData[`${this.formData.targetType}TargetIds`] = convertData.targetIds;
        }
        if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
          convertData[`${this.formData.recommendType}RecommendIds`] = convertData.recommendIds;
        }
        delete convertData.recommendIds;
        delete convertData.targetIds;
        if (convertData.offerName.trim() === '') {
          convertData.offerName = 'Target any product';
        }
        try {
          let response = null;
          if (this.mode === 'edit') {
            console.log(2);
            response = await offerApi.update(this.$route.params.id, convertData);
            this.isLoading = false;
            this.isDisable = false;
          } else {
            console.log(3);
            response = await offerApi.create({
              offer: convertData,
            });
            this.$router.push({ name: 'website.apps.bundle.update', params: { id: response.data.offer._id } });
            this.isLoading = false;
            this.isDisable = false;
          }
        } catch (error) {
          this.isLoading = false;
          this.isDisable = false;
          console.log(error);
          //empty
        }
      }
    },
    configDialogSelection(data) {
      this.dialog = true;
      this.configSelectionItem = Object.assign({}, data);
    },
    getDataSelected(data, model) {
      this.formData[model] = JSON.parse(JSON.stringify(data));
    },
  },
};
</script>

<style lang="scss" scoped></style>
